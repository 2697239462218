(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
'use strict';

$(document).ready(function () {

  $('article').prependTo($('.main-text'));
  $('article h1').appendTo($('.for-h'));

  // const { event } = require("jquery");

  $('.burger').on('click', function () {
    $(this).toggleClass("burger_active");
    $(".header__menu-wrapper").fadeToggle("slow");
    $('.header__menu').toggleClass('header__menu-active');
    // $(".small-btn").toggleClass("hide-btn");
    // $("body").toggleClass("overflow-block");
  });
  $(".header__menu-wrapper").on('click', function (e) {
    // console.log(e);
    if (e.currentTarget == e.target) {
      $(this).fadeOut("slow");
      $(".header__menu").removeClass('header__menu-active');
      $('.burger').removeClass('burger_active');
    }
    //  console.log(e.currentTarget)
  });
  //console.log(menu);
  $('[data-link]').click(function () {
    var link = $(this).data('link');
    window.open('/' + atob(link), '_self');
  });
});

// event targer -  определяю куда нажал таргет

},{}]},{},[1]);
